.branding {
    &__loader {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
    }
}

.centered-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    position: inherit;
    align-items: center;
    justify-content: center;
}

.use-common-styles .icon-spinner.size-xsmall .spinner-animation {
    transform: translate(-4.5px, -4.5px) scale(0.25) translate(25px, 25px);
}
