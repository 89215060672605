@use './_shared/vars' as *;

body {
    background: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
}

h1 {
    color: $veryDarkGray;
    font-size: 24px;
    line-height: 33px;
    font-weight: 300;
    margin: 0;
}

h2 {
    color: $veryDarkGray;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin: 0;
}

.page {
    &__logo {
        display: flex;
        position: relative;
        justify-content: center;
        margin-top: 70px;

        @media screen and (orientation: landscape) and (max-height: 450px) {
            margin-top: 7%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.white {
        background: $white;
    }

    &.blue {
        background: $mainBlue;
    }

    .logo_container {
        margin-left: 70px;
        margin-right: 70px;

        img {
            max-width: 220px;
        }
    }

    .labels-container {
        display: grid;
    }

    .centered-spinner {
        img {
            max-width: 200px;
        }
    }

    .primary-label {
        font-size: 20px;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        text-align: center;
    }

    .powered-by {
        width: 100%;
        height: 15px;
        position: absolute;
        justify-content: center;
        margin-bottom: 20px;
        line-height: 17px;
        font-size: 12px;
        color: $gray;
        text-align: center;
        bottom: 0;
    }
}
