@use '../../_shared/vars' as *;

.retry-link {
    cursor: pointer;
    color: $pureBlue;
    text-decoration: underline;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
}
